import { FC } from "react";
import { useDropzone } from "react-dropzone";
import { TrashIcon } from "@heroicons/react/20/solid";

import { ReactComponent as LoadingSpinner } from "images/loading-spinner.svg";

interface PitchDeckPdfProps {
  file: File | null;
  setFile: (file: File | null) => void;
  isUploading: boolean;
}

const PitchDeckPdf: FC<PitchDeckPdfProps> = ({
  isUploading,
  file,
  setFile,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: { "application/pdf": [] },
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
  });

  const removeFile = () => {
    setFile(null);
  };

  return (
    <>
      <div
        {...getRootProps({ className: "dropzone" })}
        className="flex justify-center mt-3 mb-5 w-full cursor-pointer items-center border-[2px] h-48 border-dashed rounded-lg border-[#314B97] bg-[#EEF2FE]"
      >
        <input {...getInputProps()} />

        <div className="text-center text-black">
          <p className="text-inherit">Drag & Drop or</p>
          <div className="whitespace-nowrap">
            <span className="text-[#18BEB3]"> Choose file</span>
            <span> to upload</span>
          </div>
        </div>
      </div>
      {file ? (
        <div className="w-full">
          <div className="w-full flex p-3 items-center justify-between bg-gradient-to-r rounded-md from-[#3B897A] to-[#18BEB3]">
            {isUploading ? (
              <div>
                <p className="text-white text-md">Uploading file...</p>
                <p className="text-sm text-white">{file.name}</p>
              </div>
            ) : (
              <p className="text-sm text-white">{file.name}</p>
            )}
            {isUploading ? (
              <LoadingSpinner />
            ) : (
              <TrashIcon
                onClick={removeFile}
                className="w-4 h-4 text-white cursor-pointer"
              />
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PitchDeckPdf;
