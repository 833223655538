import { ChangeEvent, FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { triggerSnack } from "organisms/Snack/sagaActions";
import { getUser } from "models/user/selectors";

import { Input } from "atoms/Form/Inputs";
import Button from "atoms/Button/Button";

import {
  AiaMutationAnalyzePitchUrlArgs,
  AiaMutationUploadArgs,
  AiaPitchDeckResult,
  AiaRateLimit,
  PitchDeckUrlType,
} from "gql/graphql";
import {
  AIA_UPLOAD,
  AIA_WEBSITE_UPLOAD,
  ANALYZE_PITCHDECK_URL,
} from "./queries";

import {
  mixpanelAiaUpload,
  mixpanelAiaDocSendUpload,
  mixpanelAiaUploadWebsiteAsContext,
} from "core/mixpanel/Mixpanel";
import useSetMixpanelUserProps from "core/mixpanel/useSetMixpanelUserProps";
import AiaRatesModal from "organisms/AccessControl/AiaRateToolTip";

import PitchDeckPdf from "./PitchDeckPDF";
import PitchDeckError from "./PitchDeckError";
import PitchDeckURL from "./PitchDeckURL";

interface UploadPitchDeckProps {
  isTrial: boolean;
  trialDaysLeft: number;
  websiteAsContextRates: AiaRateLimit | undefined;
}

export type PitchDeckUrlInput = {
  type: PitchDeckUrlType | null;
  value: string;
};

const UploadPitchDeck: FC<UploadPitchDeckProps> = ({
  isTrial,
  trialDaysLeft,
  websiteAsContextRates,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useSetMixpanelUserProps();

  const [pdf, setPdf] = useState<File | null>(null);
  const [pitchUrl, setPitchUrl] = useState<PitchDeckUrlInput>({
    type: null,
    value: "",
  });

  const [webUrlInputError, setWebUrlInputError] = useState(false);
  const [pitchUrlError, setPitchUrlError] = useState(false);
  const [webUrl, setWebUrl] = useState<string>("");

  const [showAiaRatesPopup, setShowAiaRatesPopup] = useState(false);

  const user = useSelector(getUser);

  const isGrantedWebsiteAsContext = Boolean(websiteAsContextRates?.remaining);

  const [uploadPdf, { loading: uploadPdfLoading, error: uploadPdfError }] =
    useMutation<{ aia: { upload: AiaPitchDeckResult } }, AiaMutationUploadArgs>(
      AIA_UPLOAD,
      {
        onCompleted: (data) => {
          const slug = data?.aia?.upload?.slug;
          const id = data?.aia?.upload?.id;
          if (slug && id) {
            handlePitchSuccess(slug, id);
          }
        },
      }
    );

  const [
    uploadWebsite,
    { loading: websiteApiLoading, error: websiteUploadError },
  ] = useMutation<{ aia: { uploadWebsite: AiaPitchDeckResult } }>(
    AIA_WEBSITE_UPLOAD,
    {
      onCompleted: (data) => {
        const slug = data?.aia?.uploadWebsite?.slug;
        const id = data?.aia?.uploadWebsite?.id;
        if (slug && id) {
          mixpanelAiaUploadWebsiteAsContext(user, slug);
          handlePitchSuccess(slug, id);
        }
      },
    }
  );

  const [
    analyzePitchUrl,
    { loading: analyzePitchUrlLoading, error: analyzePitchUrlError },
  ] = useMutation<
    { aia: { analyzePitchUrl: AiaPitchDeckResult } },
    AiaMutationAnalyzePitchUrlArgs
  >(ANALYZE_PITCHDECK_URL, {
    onCompleted: (data) => {
      const slug = data?.aia?.analyzePitchUrl?.slug;
      const id = data?.aia?.analyzePitchUrl?.id;
      if (slug && id) {
        if (pitchUrl.type === PitchDeckUrlType.Docsend) {
          mixpanelAiaDocSendUpload(user, slug);
        }
        // @TODO add pitch.com mixpanel event
        handlePitchSuccess(slug, id);
      }
    },
  });

  const handleWebUrl = (e: ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value;
    setWebUrlInputError(false);

    setWebUrl(url);
  };

  const handleSubmit = async () => {
    if (pdf) {
      return await uploadPdf({
        variables: {
          pdf,
        },
      });
    }

    if (webUrl) {
      return await uploadWebsite({
        variables: {
          url: webUrl,
        },
      });
    }

    if (pitchUrl) {
      if (!pitchUrl.value || !pitchUrl.type) {
        return dispatch(
          triggerSnack({
            message: "Please enter a valid URL",
            severity: "error",
          })
        );
      }
      await analyzePitchUrl({
        variables: {
          url: pitchUrl.value,
          type: pitchUrl.type,
        },
      });
    }
  };

  const handlePitchSuccess = (slug: string, id: string) => {
    mixpanelAiaUpload(user, slug);
    navigate(`/aia-profile/${slug}/${id}`);
  };

  const websiteUploadsLeft =
    websiteAsContextRates?.limit && websiteAsContextRates?.limit > 0
      ? `${websiteAsContextRates?.remaining}/${websiteAsContextRates?.limit} left this week.`
      : false;

  return (
    <div className="flex lg:flex-row flex-col w-full gap-6 md:gap-12">
      <div className="flex flex-col items-center pt-6 pb-12 px-6 sm:px-12 bg-white rounded-lg w-[350px] lg:w-full min-h-[240px] mx-auto">
        <div className="flex flex-col items-center justify-center w-full">
          <div className="flex justify-between w-full text-md">
            <p className="font-bold text-black">Based on pitch deck</p>
            {isTrial && <p className="text-black">{trialDaysLeft} days left</p>}
          </div>

          <PitchDeckPdf
            isUploading={uploadPdfLoading}
            file={pdf}
            setFile={setPdf}
          />
          {uploadPdfError && (
            <span className="mt-4 text-red-500">
              Something went wrong. Please try again later
            </span>
          )}

          <div className="w-full mt-2">
            <PitchDeckURL
              url={pitchUrl}
              setUrl={setPitchUrl}
              urlError={pitchUrlError}
              setUrlError={setPitchUrlError}
              label="Docsend, pitch.com or Google Drive url"
              placeholder="Paste your url here"
            />
          </div>
          {(uploadPdfError || analyzePitchUrlError) && <PitchDeckError />}
          <Button
            loading={uploadPdfLoading || analyzePitchUrlLoading}
            disabled={
              (!pdf && !pitchUrl.value) ||
              uploadPdfLoading ||
              analyzePitchUrlLoading ||
              pitchUrlError
            }
            onClick={handleSubmit}
            className=" flex w-full justify-center mt-6 text-white p-2 rounded-lg  bg-[#18BEB3] hover:opacity-90"
          >
            <span className="font-bold">Next</span>
          </Button>
        </div>
      </div>
      <div className="flex flex-col pt-6 pb-12 px-6 mb-8 sm:px-12 bg-white rounded-lg w-[350px] lg:w-full mx-auto md:h-full">
        <div className="flex flex-row justify-between">
          <p className="font-bold self-start text-black">Based on website</p>
          {websiteUploadsLeft && (
            <p className="text-sm">{websiteUploadsLeft}</p>
          )}
        </div>

        <div className="flex flex-col w-full relative">
          {showAiaRatesPopup && (
            <AiaRatesModal
              feature="WEBSITE_AS_CONTEXT"
              aiaRates={websiteAsContextRates}
              onClose={() => setShowAiaRatesPopup(false)}
              translateX={10}
              translateY={100}
            />
          )}
          <div className="flex flex-col items-center justify-center w-full">
            <div className="w-full mt-2">
              <Input
                type="url"
                id="pitchdeck-link"
                placeholder="Enter startup url"
                className="ring-1 ring-gray-300 focus:ring-[#18BEB3] focus:outline-none"
                value={webUrl}
                onChange={handleWebUrl}
                onFocus={() => {
                  if (!isGrantedWebsiteAsContext) setShowAiaRatesPopup(true);
                }}
              />
              {webUrlInputError && (
                <span className="mt-4 ml-2 text-sm text-red-500">
                  Invalid URL
                </span>
              )}
            </div>
            {websiteUploadError && <PitchDeckError />}

            <Button
              loading={websiteApiLoading}
              disabled={
                !webUrl ||
                websiteApiLoading ||
                uploadPdfLoading ||
                analyzePitchUrlLoading
              }
              onClick={handleSubmit}
              className=" flex w-full justify-center mt-6 text-white p-2 rounded-lg  bg-[#18BEB3] hover:opacity-90"
            >
              <span className="font-bold">Next</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadPitchDeck;
