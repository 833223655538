import { InvestorPlans } from "pages/plans/InvestorPlans";
import { ReactNode, createContext, useContext, useState } from "react";

const UpgradePlanModalContext = createContext({
  isOpen: false,
  openModal: () => {},
  closeModal: () => {},
});

export const UpgradePlanModalProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <UpgradePlanModalContext.Provider value={{ isOpen, openModal, closeModal }}>
      {children}
      {isOpen && <UpgradePlanModal onClose={closeModal} isOpen={isOpen} />}
    </UpgradePlanModalContext.Provider>
  );
};

export const useUpgradePlanModal = () => useContext(UpgradePlanModalContext);

const UpgradePlanModal = ({
  onClose,
  isOpen,
}: {
  onClose: () => void;
  isOpen: boolean;
}) => {
  return (
    <>
      <div
        className={`fixed inset-0 z-50 bg-black bg-opacity-70 transition-opacity ${
          isOpen ? "block" : "hidden"
        }`}
      ></div>
      <dialog
        className="fixed top-24 z-[70] p-0 bg-transparent w-full transform -translate-x-1/2 left-1/2"
        open={isOpen}
      >
        <div>
          <InvestorPlans isUpgradeModal={true} closeModal={onClose} />
        </div>
      </dialog>
    </>
  );
};
