import { FC } from "react";
import { GeneralInfoProps } from "./interfaces";
import Website from "./Website";
import Industry from "./Industry";
import HeadOffice from "./HeadOffice";
import Product from "./Product";
import BusinessRelation from "./BusinessRelation";
import Founded from "./Founded";
import TeamSize from "./TeamSize";
import StartupStage from "./StartupStage";
import SocialMedia from "./SocialMedia";
import FoundersGender from "./FoundersGender";

const GeneralInfo: FC<GeneralInfoProps> = ({
  website,
  industries,
  city,
  fullAddress,
  businessCategory,
  businessTypeNote,
  founded,
  businessModel,
  businessCategoryNote,
  companyStage,
  socials,
  teamSize,
  foundersGender,
  uiRepresentation,
}) => (
  <div className="dark:text-white/80 hidden md:flex flex-col text-sm gap-y-3">
    <Website content={website} />

    <Industry
      industries={industries}
      uiRepresentation={uiRepresentation?.industry}
    />

    <HeadOffice
      city={city}
      fullAddress={fullAddress}
      uiRepresentation={uiRepresentation?.headOffice}
    />

    <Product
      businessCategory={businessCategory}
      businessCategoryNote={businessTypeNote}
      uiRepresentation={uiRepresentation?.product}
    />

    <Founded founded={founded} uiRepresentation={uiRepresentation?.founded} />

    <BusinessRelation
      businessModel={businessModel}
      businessModelNote={businessCategoryNote}
      uiRepresentation={uiRepresentation?.businessRelation}
    />

    <TeamSize
      teamSize={teamSize}
      uiRepresentation={uiRepresentation?.teamSize}
    />

    <FoundersGender
      gender={foundersGender}
      uiRepresentation={uiRepresentation?.foundersGender}
    />

    <StartupStage
      companyStage={companyStage}
      uiRepresentation={uiRepresentation?.startupStage}
    />

    <SocialMedia socials={socials} />
  </div>
);

export default GeneralInfo;
