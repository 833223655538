import { ChangeEvent, FC } from "react";
import { ReactComponent as InfoIcon } from "images/info-icon.svg";
import { useDispatch } from "react-redux";
import Button from "atoms/Button/Button";
import { setGlobalState } from "models/globalState/sagaActions";
import { Input } from "atoms/Form/Inputs";
import { Label } from "atoms/Form/Label";
import { PitchDeckUrlType } from "gql/graphql";
import TooltipWrapper from "atoms/Tooltip/Tooltip";
import { PitchDeckUrlInput } from "./UploadPitchDeck";
import { mapSourceLinkType } from "./utils";

interface PitchdeckURLProps {
  url: PitchDeckUrlInput;
  setUrl: (url: PitchDeckUrlInput) => void;
  urlError?: boolean;
  setUrlError: (error: boolean) => void;
  label: string;
  placeholder?: string;
}

const PitchdeckURL: FC<PitchdeckURLProps> = ({
  url,
  setUrl,
  urlError,
  setUrlError,
  placeholder,
  label,
}) => {
  const docsendPattern = /^https:\/\/docsend\.com\/view\/[A-Za-z0-9_-]+$/;
  const pitchDotComPatten = /^https:\/\/pitch\.com\/v\/[A-Za-z0-9_-]+$/;
  const gDrivePattern =
    /^https:\/\/drive\.google\.com\/file\/d\/[A-Za-z0-9_-]+\/view.*$/;

  const dispatch = useDispatch();
  const onHelpClicked = () => {
    dispatch(
      setGlobalState({
        showSupportPanel: true,
      })
    );
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setUrlError(false);
    const set = (value: string) => {
      if (docsendPattern.test(value)) {
        setUrl({ type: PitchDeckUrlType.Docsend, value });
      } else if (pitchDotComPatten.test(value)) {
        setUrl({ type: PitchDeckUrlType.Pitchdotcom, value });
      } else if (gDrivePattern.test(value)) {
        setUrl({ type: PitchDeckUrlType.Gdrive, value });
      } else {
        setUrlError(true);
        setUrl({ type: null, value });
      }
    };
    setUrlError(false);
    set(e.target.value);
  };

  return (
    <div className="w-full mt-2">
      <Label className="flex items-center mb-2 gap-1" htmlFor="pitchdeck-link">
        {label}
        <TooltipWrapper
          place="right"
          content={
            <div className="text-white">
              <p className="text-white">
                Got a pitch deck via link? No worries, we got this covered!
              </p>
              <p className="text-white">
                Paste the link into the input field below and click Next to
                analyze the pitch deck.
              </p>
              <p className="text-white">We currently support:</p>
              <ul
                style={{
                  listStyleType: "disc",
                  paddingLeft: "1em",
                }}
              >
                <li>Docsend</li>
                <li>pitch.com</li>
                <li> Google Drive</li>
              </ul>
              <p className="text-white">
                If you have a link that is not supported, please contact our
                support and we will help you.
              </p>
              <Button
                size="small"
                color="secondary"
                onClick={onHelpClicked}
                className="my-2 font-bold p-2 rounded-lg"
              >
                Contact
              </Button>
            </div>
          }
        >
          <InfoIcon />
        </TooltipWrapper>
      </Label>
      <Input
        type="url"
        id="pitchdeck-link"
        placeholder={placeholder}
        className="ring-1 ring-gray-300 focus:ring-[#18BEB3] focus:outline-none"
        value={url.value}
        onChange={handleInput}
      />

      {urlError && (
        <span className="mt-4 text-sm text-red-500">
          Please provide a correct Docsend, pitch.com or Google Drive url
        </span>
      )}
      {url.type && (
        <span className="mt-4 text-sm">{`Found a ${mapSourceLinkType(
          url.type
        )} link`}</span>
      )}
    </div>
  );
};

export default PitchdeckURL;
